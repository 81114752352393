import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ArrowNarrowLeftIcon, LockClosedIcon, MailIcon } from '@heroicons/react/solid';
import { Col, Row, Form, Card, Button, Container, InputGroup, Spinner } from 'react-bootstrap';
import { Link, useNavigate, generatePath } from 'react-router-dom';

import { ErrorAlert } from '#atoms';
import { Routes } from '#Routes';
import BgImage from '#assets/img/illustrations/signin.svg';
import { useAuth, useCustomForm } from '#hooks';
import { emailRegex, ERRORS } from '#const';

const RegisterPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { error } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    watch,
    translateFormError,
    formState: { isSubmitSuccessful, isSubmitting, errors },
  } = useCustomForm();
  const isSuccess = isSubmitSuccessful && !error?.length;

  const { register: authRegister, redirectWhenLoggedIn } = useAuth();

  useEffect(() => {
    const language = searchParams.get('language');
    if (language && ['en', 'pl'].includes(language)) {
      i18n.changeLanguage(language);
    }
  }, [searchParams, i18n]);

  useEffect(() => {
    const cleanup = redirectWhenLoggedIn();

    if (isSuccess) {
      navigate(generatePath('/auth/account-created'));
    }

    return () => {
      if (cleanup && typeof cleanup === 'function') {
        cleanup();
      }
    };
  }, [isSuccess, navigate, redirectWhenLoggedIn]);

  const onFormSubmit = async (data) => {
    if (data.password !== data.passwordRepeat) {
      return;
    }

    data.email = data.email.toLowerCase();
    
    const payload = {
      ...data,
      language: i18n.language || 'pl'
    };

    await authRegister(payload);
  };

  return (
    <main>
      <section className='vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center'>
        <Container>
          <p className='text-center'>
            <Card.Link
              as={Link}
              to={process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_URL : Routes.Home.path}
              className='d-flex align-items-center justify-content-center'
            >
              <ArrowNarrowLeftIcon className='icon icon-xs me-2' />
              {t('auth.backToHomepage')}
            </Card.Link>
          </p>
          <Row
            className='justify-content-center form-bg-image'
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col xs={12} className='d-flex align-items-center justify-content-center'>
              <div className='bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <div className='text-center text-md-center mb-4 mt-md-0'>
                  <h3 className='mb-0'>{t('auth.createAccount')}</h3>
                </div>
                <Form className='mt-4' onSubmit={handleSubmit(onFormSubmit)}>
                  <Form.Group id='email' className='mb-4'>
                    <Form.Label>{t('auth.email')}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className='icon icon-xs text-gray-600' />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type='email'
                        placeholder={t('auth.email-placeholder')}
                        autoComplete='email'
                        isInvalid={!!errors.email}
                        {...register('email', { required: true, pattern: emailRegex })}
                      />
                      <Form.Control.Feedback type='invalid'>{'email'}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id='password' className='mb-4'>
                    <Form.Label>{t('auth.password')}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className='icon icon-xs text-gray-600' />
                      </InputGroup.Text>
                      <Form.Control
                        type='password'
                        placeholder={t('auth.password-placeholder')}
                        autoComplete='new-password'
                        isInvalid={!!errors.password}
                        {...register('password', { required: true, minLength: 8 })}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {translateFormError('password')}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id='passwordRepeat' className='mb-4'>
                    <Form.Label>{t('auth.passwordConfirm')}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className='icon icon-xs text-gray-600' />
                      </InputGroup.Text>
                      <Form.Control
                        type='password'
                        placeholder={t('auth.passwordConfirm')}
                        autoComplete='new-password'
                        isInvalid={!!errors.passwordRepeat}
                        {...register('passwordRepeat', {
                          required: true,
                          minLength: 8,
                          validate: (val) => {
                            if (watch('password') !== val) {
                              return ERRORS.PASSWORD_MISMATCH;
                            }
                          },
                        })}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {translateFormError('passwordRepeat')}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id='termsAndConditions' className='mb-4'>
                    <Form.Check>
                      <Form.Check.Input
                        type='checkbox'
                        className='me-2'
                        isInvalid={!!errors.termsAndConditions}
                        {...register('termsAndConditions', { required: true })}
                      />
                      <Form.Label>
                        {t('auth.agree')}&nbsp;
                        <Card.Link as={Link} to={Routes.Terms.path} className='fw-bold'>
                          {t('auth.termsAndConditions')}
                        </Card.Link>
                      </Form.Label>
                      <Form.Control.Feedback type='invalid'>
                        {translateFormError('termsAndConditions')}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>

                  {!isSuccess && (
                    <>
                      {isSubmitSuccessful && <ErrorAlert error={error} />}

                      <div className='d-grid'>
                        <Button variant='gray-800' size='lg' type='submit' disabled={isSubmitting}>
                          {isSubmitting ? <Spinner size='sm' /> : t('auth.register')}
                        </Button>
                      </div>
                    </>
                  )}
                </Form>

                <div className='d-flex justify-content-center align-items-center mt-4'>
                  <span className='fw-normal'>
                    {t('auth.alreadyHaveAccount')}{' '}
                    <Card.Link as={Link} to={Routes.Login.path} className='fw-bold'>
                      {t(`auth.login`)}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default RegisterPage;
