import { baseApi } from './baseApi';

const TAG = 'MedicalDocuments';

const makeCompareQueries =
  (tag) =>
  async (id, { dispatch, queryFulfilled, getCacheEntry }) => {
    const entryData = getCacheEntry()?.data?.results || [];

    try {
      const { data } = await queryFulfilled;
      const { results } = data;

      const removedDocs = entryData.filter((oldDoc) => {
        return !results.find((newDoc) => newDoc.id === oldDoc.id);
      });

      if (removedDocs.length > 0) {
        const docTags = removedDocs.map(({ id }) => ({ type: TAG, id }));
        dispatch(baseApi.util.invalidateTags([TAG, ...docTags, tag]));
      }
    } catch (err) {
      // `onError` side-effect
      console.log('errrror', err);
    }
  };

const medicalDocumentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchMedicalDocument: builder.query({
      query: ({ id }) => ({
        url: `/medical-documents/${id}/`,
        method: 'GET',
      }),
      transformResponse: (data) => {
        // Provide extended urls to list of images
        console.log('query data: ', data);
        if (data?.image_url?.length > 0) {
          data.image_url.forEach((url, index) => {
            data.data.images[index].url = url;
          });
        }
        return data?.data;
      },
      providesTags: (data) => (data?.data ? [{ type: TAG, id: data.id }, TAG] : [TAG]),
    }),
    fetchMedicalDocuments: builder.query({
      query: ({ sort, ...params } = {}) => ({
        url: '/medical-documents/?' + new URLSearchParams({
          ...(sort && { sort }),
          ...params
        }).toString(),
        method: 'GET',
      }),
      providesTags: [TAG],
    }),
    fetchMedicalDocumentProcessing: builder.query({
      query: () => ({
        url: `/medical-documents/?process_status=IN_PROGRESS`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return ['MedicalDocumentsProcessing'];
      },
      onQueryStarted: makeCompareQueries('MedicalDocumentsProcessing'),
    }),
    fetchMedicalDocumentReporting: builder.query({
      query: () => ({
        url: `/medical-documents/?report_status=IN_PROGRESS`,
        method: 'GET',
      }),
      providesTags: ['MedicalDocumentsReporting'],
      onQueryStarted: makeCompareQueries('MedicalDocumentsReporting'),
    }),
    createMedicalDocument: builder.mutation({
      query: (params) => {
        const form = new FormData();
        let keys = ['document_type', 'process_type', 'tags', 'document_images'];

        if (params.process_type === 'MANUAL') {
          keys = Object.keys(params);
        }

        keys.forEach((key) => {
          if (!params[key]) return;
          if (key === 'document_images') {
            params[key].forEach((image) => {
              form.append('file', image);
            });
          } else if (key === 'tags') {
            // Handle tags as a simple array without stringification
            params[key].forEach((tag) => {
              form.append('tags', tag);
            });
          } else {
            if (typeof params[key] === 'string') {
              form.append(key, params[key]);
            } else {
              form.append(key, JSON.stringify(params[key]));
            }
          }
        });

        return {
          url: '/medical-documents/',
          method: 'POST',
          body: form,
        };
      },
      invalidatesTags: [TAG],
    }),
    processMedicalDocument: builder.mutation({
      query: ({ uuid }) => ({
        url: `/medical-documents/process/`,
        method: 'POST',
        body: { uuid },
      }),
      invalidatesTags: (result, error, arg) => [
        'MedicalDocumentsProcessing',
        TAG,
        { type: TAG, id: arg.id },
      ],
    }),
    confirmMedicalDocument: builder.mutation({
      query: ({ uuid }) => ({
        url: `/medical-documents/confirm/`,
        method: 'POST',
        body: { uuid },
      }),
      invalidatesTags: (result, error, arg) => [TAG, { type: TAG, id: arg.id }],
    }),
    reportMedicalDocument: builder.mutation({
      query: ({ uuid, id }) => ({
        url: `/medical-documents/report/`,
        method: 'POST',
        body: { uuid },
      }),
      invalidatesTags: (result, error, arg) => [
        'MedicalDocumentsReporting',
        TAG,
        { type: TAG, id: arg.id },
      ],
    }),
    updateMedicalDocument: builder.mutation({
      query: ({ id, ...params }) => ({
        url: `/medical-documents/${id}/`,
        method: 'PATCH',
        body: { ...params },
      }),
      invalidatesTags: (result, error, arg) => [TAG, { type: TAG, id: arg.id }],
    }),
    deleteMedicalDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/medical-documents/${id}/`,
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: [TAG],
    }),
  }),
});

export const {
  useFetchMedicalDocumentQuery,
  useLazyFetchMedicalDocumentQuery,
  useFetchMedicalDocumentsQuery,
  useFetchMedicalDocumentProcessingQuery,
  useFetchMedicalDocumentReportingQuery,
  useCreateMedicalDocumentMutation,
  useProcessMedicalDocumentMutation,
  useConfirmMedicalDocumentMutation,
  useReportMedicalDocumentMutation,
  useUpdateMedicalDocumentMutation,
  useDeleteMedicalDocumentMutation,
} = medicalDocumentsApi;
