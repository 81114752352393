import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Alert } from 'react-bootstrap';
import { useSendContactMessageMutation } from '../store/apis';

const INITIAL_FORM_STATE = {
  name: '',
  email: '',
  subject: '',
  message: '',
};

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });
  
  const [sendContactMessage, { isLoading: isSubmitting }] = useSendContactMessageMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData(INITIAL_FORM_STATE);
    // Clear status after 5 seconds
    setTimeout(() => {
      setSubmitStatus({ type: '', message: '' });
    }, 5000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ type: '', message: '' });

    try {
      await sendContactMessage(formData).unwrap();
      setSubmitStatus({
        type: 'success',
        message: t('contact-form.success-message'),
      });
      resetForm();
    } catch (error) {
      console.error('Error sending message:', error);
      setSubmitStatus({
        type: 'danger',
        message: t('contact-form.error-message'),
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {submitStatus.message && (
        <Alert variant={submitStatus.type} className="mb-4">
          {submitStatus.message}
        </Alert>
      )}
      
      <Form.Group className='mb-4'>
        <Form.Label htmlFor='formName'>{t('contact-form.name')}</Form.Label>
        <Form.Control
          type='text'
          id='formName'
          name='name'
          value={formData.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className='mb-4'>
        <Form.Label htmlFor='formEmail'>{t('contact-form.account-email')}</Form.Label>
        <Form.Control
          type='email'
          id='formEmail'
          name='email'
          value={formData.email}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className='mb-4'>
        <Form.Label htmlFor='formSubject'>{t('contact-form.subject')}</Form.Label>
        <Form.Select
          id='formSubject'
          name='subject'
          value={formData.subject}
          onChange={handleChange}
          required
        >
          <option value=''>{t('contact-form.select-option')}</option>
          <option value={t('contact-form.subject_options.general')}>
            {t('contact-form.subject_options.general')}
          </option>
          <option value={t('contact-form.subject_options.technical')}>
            {t('contact-form.subject_options.technical')}
          </option>
          <option value={t('contact-form.subject_options.billing')}>
            {t('contact-form.subject_options.billing')}
          </option>
          <option value={t('contact-form.subject_options.complaint')}>
            {t('contact-form.subject_options.complaint')}
          </option>
          <option value={t('contact-form.subject_options.account-deletion')}>
            {t('contact-form.subject_options.account-deletion')}
          </option>
        </Form.Select>
      </Form.Group>

      <Form.Group className='mb-4'>
        <Form.Label htmlFor='formMessage'>{t('contact-form.message')}</Form.Label>
        <Form.Control
          as='textarea'
          id='formMessage'
          name='message'
          value={formData.message}
          onChange={handleChange}
          required
          rows={4}
        />
      </Form.Group>

      <Button variant='logo-green' type='submit' className='btn-block' disabled={isSubmitting}>
        {isSubmitting ? t('contact-form.sending') : t('contact-form.send')}
      </Button>
    </Form>
  );
};

export default ContactForm;
