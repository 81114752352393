import { baseApi } from './baseApi';

export const contactApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sendContactMessage: builder.mutation({
      query: (formData) => ({
        url: '/contact/',
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const { useSendContactMessageMutation } = contactApi;
