import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArrowNarrowLeftIcon, LockClosedIcon } from '@heroicons/react/solid';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Collapse,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';

import { ErrorAlert } from '#atoms';
import { authForgotPasswordSet } from '#store/actions/authActions';
import { Routes } from '#Routes';

const ForgotPasswordSetPage = () => {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting },
  } = useForm();
  const uid = searchParams.get('uid');
  const token = searchParams.get('token');
  const isSuccess = isSubmitSuccessful && !error?.length;

  const onFormSubmit = async (data) => {
    data.uid = uid;
    data.token = token;

    await dispatch(authForgotPasswordSet(data));
  };

  return (
    <main>
      <section className='vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center'>
        <Container>
          <Row className='justify-content-center'>
            <p className='text-center'>
              <Card.Link
                as={Link}
                to={Routes.Login.path}
                className='d-flex align-items-center justify-content-center'
              >
                <ArrowNarrowLeftIcon className='icon icon-xs me-2' />
                {t('auth.backToHomepage')}
              </Card.Link>
            </p>
            <Col xs={12} className='d-flex align-items-center justify-content-center'>
              <div className='signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500'>
                <h3>{t('auth.changePassword')}</h3>
                <p className='mb-4'>{t('auth.changePasswordSubmit')}</p>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className='mb-4'>
                    <Form.Group id='password' className='mb-4'>
                      <Form.Label>{t('auth.passwordNew')}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <LockClosedIcon className='icon icon-xs text-gray-600' />
                        </InputGroup.Text>
                        <Form.Control
                          type='password'
                          placeholder={t('auth.passwordNew')}
                          autoComplete='new-password'
                          {...register('password', { required: true, minLength: 8 })}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id='confirmPassword' className='mb-4'>
                      <Form.Label>{t('auth.passwordNewConfirm')}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <LockClosedIcon className='icon icon-xs text-gray-600' />
                        </InputGroup.Text>
                        <Form.Control
                          type='password'
                          placeholder={t('auth.passwordNewConfirm')}
                          autoComplete='new-password'
                          {...register('passwordRepeat', { required: true, minLength: 8 })}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>

                  {isSuccess && (
                    <Collapse in={true} appear={true}>
                      <div className='d-grid'>
                        <Alert variant='success'>{t(`auth.setNewPasswordSuccessPrompt`)}</Alert>
                      </div>
                    </Collapse>
                  )}

                  {!isSuccess && (
                    <>
                      {isSubmitSuccessful && <ErrorAlert error={error} />}

                      <div className='d-grid'>
                        <Button variant='primary' size='lg' type='submit' disabled={isSubmitting}>
                          {isSubmitting ? <Spinner size='sm' /> : t('auth.setNewPassword')}
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ForgotPasswordSetPage;
