import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function Headers() {
  const { t } = useTranslation();
  const { lang } = useParams();

  const currentLang = lang || 'pl';
  const baseUrl = 'https://medify.me';

  // Determine the canonical URL (each language version is canonical for itself)
  const canonicalUrl = currentLang === 'pl' ? baseUrl : `${baseUrl}/${currentLang}`;

  return (
    <Helmet>
      <html lang={currentLang} />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
      <title>{t('headers.title')}</title>
      <meta name="description" content={t('headers.description')} />
      <meta name="format-detection" content="telephone=no" />
      <link rel="canonical" href={canonicalUrl} />
      {/* Use hreflang tags to indicate language relations */}
      <link rel="alternate" hrefLang="en" href={`${baseUrl}/en`} />
      <link rel="alternate" hrefLang="pl" href={baseUrl} />
      {/* Add x-default hreflang for the default language version */}
      <link rel="alternate" hrefLang="x-default" href={baseUrl} />
      {/* Add self-referencing hreflang tag */}
      <link rel="alternate" hrefLang={currentLang} href={canonicalUrl} />
      {/* Open Graph tags */}
      <meta property="og:title" content={t('headers.title')} />
      <meta property="og:description" content={t('headers.description')} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={currentLang === 'pl' ? 'pl_PL' : 'en_US'} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={t('headers.title')} />
      <meta name="twitter:description" content={t('headers.description')} />
    </Helmet>
  );
}

export default Headers;
