import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import Home from '#pages/HomePage';
import Layout from '#components/LayoutSidebar';
import ProtectedPage from '#pages/ProtectedPage';
import ProfileUpdate from '#pages/ProfileUpdatePage';
import SubscriptionPage from '#pages/subscription/SubscriptionPage';
import AccountPage from '#pages/account/AccountPage';
import ContactPage from '#pages/ContactPage';

import DashboardPage from '#pages/DashboardPage';

import DisclaimersPage from '#pages/DisclaimersPage';
import PrivacyPage from '#pages/PrivacyPage';
import TermsPage from '#pages/TermsPage';
import { ServerErrorPage } from '#pages/ServerErrorPage';

import LoginPage from '#pages/authentication/LoginPage';
import RegisterPage from '#pages/authentication/RegisterPage';
import AccountCreatedPage from '#pages/authentication/AccountCreatedPage';
import ChangePasswordPage from '#pages/authentication/ChangePasswordPage';
import ForgotPasswordPage from '#pages/authentication/ForgotPasswordPage';
import ForgotPasswordSetPage from '#pages/authentication/ForgotPasswordSetPage';
import EmailVerificationPage from '#pages/authentication/EmailVerificationPage';
import ResendEmailVerificationPage from '#pages/authentication/ResendEmailVerificationPage';

import MedicalDocumentPage from '#pages/medical-documents/MedicalDocumentPage';
import MedicalDocumentEditPage from '#pages/medical-documents/MedicalDocumentEditPage';
import MedicalDocumentCreatePage from '#pages/medical-documents/MedicalDocumentCreatePage';
import MedicalDocumentsListPage from '#pages/medical-documents/MedicalDocumentsListPage';

import LaboratoryTestsListPage from '#pages/laboratory-tests/LaboratoryTestsListPage';

import HealthReportPage from '#pages/health-reports/HealthReportPage';
import HealthReportCreatePage from '#pages/health-reports/HealthReportCreatePage';
import HealthReportsListPage from '#pages/health-reports/HealthReportsListPage';

import HealthDataSurveyPage from '#pages/health-data/HealthDataSurveyPage';

import Loading from '#components/Loading/Loading';

import PresentationPage from '#pages/Presentation';

import { Routes } from '#Routes';

const Redirects = [
  {
    path: '/manage-subscription',
    element: <Navigate to={Routes.AccountSubscribe.path} replace />,
  },
];

export const Router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to='/pl' replace />,
  },
  // {
  //   path: '/:lang',
  //   element: <DashboardPage />,
  //   loader: Loading,
  // },
  // ...Redirects,
  // {
  //   ...Routes.Home,
  //   element: <DashboardPage />,
  //   loader: Loading,
  // },
  {
    path: '/auth/:lang/login',
    element: <LoginPage />,
    loader: Loading,
  },
  {
    path: '/auth/login',
    element: <Navigate to="/auth/pl/login" replace />,
  },
  {
    ...Routes.Register,
    element: <RegisterPage />,
    loader: Loading,
  },
  {
    ...Routes.AccountCreated,
    element: <AccountCreatedPage />,
    loader: Loading,
  },
  {
    ...Routes.ForgotPassword,
    element: <ForgotPasswordPage />,
    loader: Loading,
  },
  {
    ...Routes.ForgotPasswordSet,
    element: <ForgotPasswordSetPage />,
    loader: Loading,
  },
  {
    ...Routes.EmailVerification,
    element: <EmailVerificationPage />,
    loader: Loading,
  },
  {
    ...Routes.ResendEmailVerification,
    element: <ResendEmailVerificationPage />,
    loader: Loading,
  },
  {
    ...Routes.Disclaimers,
    element: <DisclaimersPage />,
    loader: Loading,
  },
  {
    ...Routes.Privacy,
    element: <PrivacyPage />,
    loader: Loading,
  },
  {
    ...Routes.Terms,
    element: <TermsPage />,
    loader: Loading,
  },
  // {
  //   ...Routes.Contact,
  //   element: <ContactPage />,
  //   loader: Loading,
  // },
  {
    ...Routes.ServerError,
    element: <ServerErrorPage />,
    loader: Loading,
  },
  // {
  //   ...Routes.Presentation,
  //   element: <PresentationPage />,
  //   loader: Loading,
  // },
  {
    element: <ProtectedPage />,
    children: [
      {
        ...Routes.ChangePassword,
        element: <ChangePasswordPage />,
        loader: Loading,
      },
      {
        element: <Layout />,
        children: [
          {
            path: '/:lang',
            element: <DashboardPage />,
            loader: Loading,
          },
          ...Redirects,
          {
            ...Routes.Home,
            element: <DashboardPage />,
            loader: Loading,
          },
          {
            ...Routes.Profile,
            element: <ProfileUpdate />,
            loader: Loading,
          },
          {
            ...Routes.ProfileEdit,
            element: <ProfileUpdate />,
            loader: Loading,
          },
          {
            ...Routes.Dashboard,
            element: <DashboardPage />,
            loader: Loading,
          },
          {
            ...Routes.Account,
            element: <AccountPage />,
            loader: Loading,
          },
          {
            ...Routes.AccountSubscribe,
            element: <SubscriptionPage />,
            loader: Loading,
          },
          {
            ...Routes.MedicalDocumentsList,
            element: <MedicalDocumentsListPage />,
            loader: Loading,
          },
          {
            ...Routes.MedicalDocumentCreate,
            element: <MedicalDocumentCreatePage />,
            loader: Loading,
          },
          {
            ...Routes.MedicalDocument,
            element: <MedicalDocumentPage />,
            loader: Loading,
          },
          {
            ...Routes.MedicalDocumentEdit,
            element: <MedicalDocumentEditPage />,
            loader: Loading,
          },
          {
            ...Routes.HealthReportsList,
            element: <HealthReportsListPage />,
            loader: Loading,
          },
          {
            ...Routes.LaboratoryTestsList,
            element: <LaboratoryTestsListPage />,
            loader: Loading,
          },
          {
            ...Routes.HealthReportCreate,
            element: <HealthReportCreatePage />,
            loader: Loading,
          },
          {
            ...Routes.HealthReport,
            element: <HealthReportPage />,
            loader: Loading,
          },
          {
            ...Routes.HealthDataSurvey,
            element: <HealthDataSurveyPage />,
            loader: Loading,
          },
          {
            ...Routes.Contact,
            element: <ContactPage />,
            loader: Loading,
          },
        ],
      },
    ],
  },
]);
